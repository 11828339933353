import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faFacebook,
  faLinkedin,
  faTwitterSquare,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';

import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBars,
  faEnvelopeSquare,
  faHistory,
  faPlay,
  faShare,
  faSortDown,
  faSpinner,
  faTimes,
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowCircleLeft,
  faArrowCircleRight,
  faBars,
  faEnvelope,
  faEnvelopeSquare,
  faFacebook,
  faHistory,
  faLinkedin,
  faPhone,
  faPlay,
  faMapMarkerAlt,
  faShare,
  faSortDown,
  faSpinner,
  faTimes,
  faTwitterSquare,
  faWhatsappSquare,
  faCaretDown,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
