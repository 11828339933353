<template>
  <main class="flex flex-col min-h-screen">
    <navigation-bar/>
    <nuxt class="flex-grow"/>
    <app-footer/>
  </main>
</template>

<script>
import AppFooter from '~/layouts/partials/AppFooter';
import NavigationBar from '~/layouts/partials/NavigationBar';

export default {
  components: {
    AppFooter,
    NavigationBar,
  },
};
</script>
