export const state = () => ({
  kolfPublications: [],
  kolfNextPage: 1,
  colfPublications: [],
  colfNextPage: 1,
  golfPublications: [],
  golfNextPage: 1,
});

export const getters = {
  publications: state => (category) => {
    return state[`${category}Publications`];
  },
  nextPage: state => (category) => {
    return state[`${category}NextPage`];
  },
};

export const mutations = {
  SET_PUBLICATIONS(state, {category, publications}) {
    state[`${category}Publications`] = publications;
  },
  SET_NEXT_PAGE(state, {category, nextPage}) {
    state[`${category}NextPage`] = nextPage;
  },
};

export const actions = {
  setPublications({commit}, publications) {
    commit('SET_PUBLICATIONS', publications);
  },
  setNextPage({commit}, nextPage) {
    commit('SET_NEXT_PAGE', nextPage);
  },
};
