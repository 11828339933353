<template>
  <div v-html="html"/>
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: null,
    },
  },
};
</script>
