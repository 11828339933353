<template>
  <nav class="shadow-box py-2 fixed bg-white w-full z-20">
    <section class="container relative flex items-center justify-between gap-3">
      <nuxt-link to="/" aria-label="Ga naar de homepage" class="-ml-6 xl:ml-0 h-8 sm:h-10">
        <client-only>
          <lottie :options="defaultOptions" @animCreated="handleAnimation"/>
          <template slot="placeholder">
            <img
              :src="require('~/assets/img/gg-logo.svg')"
              alt="Golfgeschiedenis"
              aria-hidden="true"
              class="h-8 sm:h-10 object-cover"
            >
          </template>
        </client-only>
      </nuxt-link>
      <div class="hidden md:block md:w-4/12">
        <search-bar in-header/>
      </div>

      <!-- Main menu -->
      <ul class="hidden w-1/2 lg:w-4/12 xxl:w-3/12 md:flex justify-between relative">
        <li
          v-for="link in mainMenu"
          :key="link.text"
          class="relative"
          @mouseover="subMenu = link.text"
          @mouseleave="subMenu = null"
        >
          <template>
            <nuxt-link
              :to="link.url"
              :aria-label="link.text"
              :class="{'text-primary border-b-2 border-primary bold-link': $route.path === link.url}"
              class="flex items-start border-b-2 border-transparent hover:text-primary hover:border-primary bold-link-hover"
            >
              {{ link.text }}
              <font-awesome-icon v-if="link.children" :icon="['fas', 'sort-down']" class="ml-2"/>
            </nuxt-link>
          </template>
          <!-- First gen children -->
          <template v-if="link.children">
            <transition name="fade">
              <ul v-show="subMenu === link.text" class="sub-menu absolute bg-white shadow-lg w-40 z-20">
                <li v-for="child in link.children" :key="child.text" class="p-2">
                  <nuxt-link
                    :to="child.url"
                    :aria-label="child.text"
                    class="hover:text-primary border-b-2 border-transparent hover:border-primary bold-link-hover font-semibold truncate"
                    v-text="child.text"
                  />
                  <!-- Second gen children -->
                  <ul class="w-40 z-20">
                    <li v-for="gChild in child.children" :key="gChild.text" class="p-2">
                      <nuxt-link
                        :to="gChild.url"
                        :aria-label="gChild.text"
                        class="hover:text-primary border-b-2 border-transparent hover:border-primary bold-link-hover font-semibold truncate"
                        v-text="gChild.text"
                      />
                      <!-- Third gen children -->
                      <ul class="w-40 z-20">
                        <li v-for="ggChild in gChild.children" :key="ggChild.text" class="p-3">
                          <nuxt-link
                            :to="ggChild.url"
                            :aria-label="ggChild.text"
                            class="hover:text-primary border-b-2 border-transparent hover:border-primary bold-link-hover font-semibold truncate"
                            v-text="ggChild.text"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </transition>
          </template>
        </li>
        <li>
          <span>
            <nuxt-link
              to="/en"
              :class="$route.path === '/en' ? 'active-link' : ''"
              class="hover:text-primary bold-link-hover"
              aria-label="Switch to English"
            >
              EN
            </nuxt-link>
            |
            <nuxt-link
              to="/"
              :class="$route.path !== '/en' ? 'active-link' : ''"
              class="hover:text-primary bold-link-hover"
              aria-label="Verander naar Nederlands"
            >
              NL
            </nuxt-link>
          </span>
        </li>
      </ul>

      <!-- Mobile menu -->
      <button
        class="md:hidden mr-6 sm:mr-0"
        :aria-label="!open ? 'Open het menu' : 'Sluit het menu'"
        @click="open = !open"
      >
        <font-awesome-icon v-if="!open" :icon="['fas', 'bars']" size="lg" class="text-primary" aria-hidden="true"/>
        <font-awesome-icon v-else :icon="['fas', 'times']" size="lg" class="text-primary" aria-hidden="true"/>
      </button>
      <template v-if="open">
        <div class="fixed left-0 top-10 sm:top-13 w-screen min-h-screen bg-white">
          <ul class="relative flex flex-col items-center sm:justify-center h-80v overflow-auto py-24">
            <li v-for="link in mainMenu" :key="link.text" class="relative py-3 text-center">
              <template>
                <nuxt-link
                  :to="link.url"
                  :aria-label="link.text"
                  class="font-semibold"
                  v-text="link.text"
                />
                <font-awesome-icon
                  v-if="link.children"
                  :icon="['fas', 'sort-down']"
                  class="icon"
                  @click="toggleSubMenu(link.text)"
                />
              </template>
              <!-- First generation children -->
              <template v-if="link.children">
                <transition name="fade">
                  <ul v-show="subMenu === link.text" class="w-40 z-20">
                    <li v-for="child in link.children" :key="child.text" class="p-3">
                      <nuxt-link
                        :to="child.url"
                        :aria-label="child.text"
                        class="truncate"
                        v-text="child.text"
                      />
                      <!-- Second gen children -->
                      <ul class="w-40 z-20">
                        <li v-for="gChild in child.children" :key="gChild.text" class="p-3">
                          <nuxt-link
                            :to="gChild.url"
                            :aria-label="gChild.text"
                            class="truncate"
                            v-text="gChild.text"
                          />
                          <!-- Third gen children -->
                          <ul class="w-40 z-20">
                            <li v-for="ggChild in gChild.children" :key="ggChild.text" class="p-3">
                              <nuxt-link
                                :to="ggChild.url"
                                :aria-label="ggChild.text"
                                class="truncate"
                                v-text="ggChild.text"
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </transition>
              </template>
            </li>
            <li class="py-3">
              <span>
                <nuxt-link
                  to="/en"
                  :class="$route.path === '/en' ? 'active-link' : ''"
                  class="hover:text-primary"
                  aria-label="Switch to English"
                >
                  EN
                </nuxt-link>
                |
                <nuxt-link
                  to="/"
                  :class="$route.path !== '/en' ? 'active-link' : ''"
                  class="hover:text-primary"
                  aria-label="Verander naar Nederlands"
                >
                  NL
                </nuxt-link>
              </span>
            </li>
            <li class="py-3">
              <search-bar in-header/>
            </li>
            <li class="pt-6">
              <nuxt-link
                v-if="$route.path === '/en'"
                to="/contact"
                aria-label="Contribute to golfgeschiedenis.nl"
                class="flex justify-center items-center w-full font-semibold bg-secondary text-white text-xs xxl:text-sm uppercase px-6 sm:px-4 py-6"
              >
                Draag bij <span class="hidden sm:inline ml-1">aan golfgeschiedenis.nl</span>
                <font-awesome-icon
                  :icon="['fas', 'arrow-circle-right']"
                  size="lg"
                  class="ml-1 md:ml-3"
                  aria-hidden="true"
                />
              </nuxt-link>
              <nuxt-link
                v-else
                to="/contact"
                :aria-label="this.$route.path === '/en' ? 'Draag bij aan golfgeschiedenis.nl': 'Contribute to golfgeschiedenis.nl'"
                class="flex justify-center items-center w-full font-semibold bg-secondary text-white text-xs xxl:text-sm uppercase px-6 sm:px-4 py-6"
              >
                Draag bij <span class="hidden sm:inline ml-1">aan golfgeschiedenis.nl</span>
                <font-awesome-icon
                  :icon="['fas', 'arrow-circle-right']"
                  size="lg"
                  class="ml-1 md:ml-3"
                  aria-hidden="true"
                />
              </nuxt-link>
            </li>
          </ul>
        </div>
      </template>
      <div
        v-if="!open"
        class="absolute left-50pr lg:left-67pr xxl:left-75pr right-0 top-40p -bottom-50p sm:top-48p sm:-bottom-60p bg-secondary bg-opacity-75 z-10 flex justify-center items-center"
      >
        <nuxt-link
          v-if="$route.path === '/en'"
          to="/contact"
          aria-label="Draag bij aan golfgeschiedenis.nl"
          class="flex justify-center items-center w-full font-semibold text-white text-sm xxl:text-base uppercase"
        >
          Contribute <span class="hidden sm:inline ml-1">to golfgeschiedenis.nl</span>
          <font-awesome-icon :icon="['fas', 'arrow-circle-right']" size="lg" class="ml-1 md:ml-3" aria-hidden="true"/>
        </nuxt-link>
        <nuxt-link
          v-else
          to="/contact"
          aria-label="Draag bij aan golfgeschiedenis.nl"
          class="flex justify-center items-center w-full font-semibold text-white text-sm xxl:text-base uppercase"
        >
          Draag bij <span class="hidden sm:inline ml-1">aan golfgeschiedenis.nl</span>
          <font-awesome-icon :icon="['fas', 'arrow-circle-right']" size="lg" class="ml-1 md:ml-3" aria-hidden="true"/>
        </nuxt-link>
      </div>
    </section>
  </nav>
</template>

<script>
import {mapState} from 'vuex';
import Lottie from 'vue-lottie/src/lottie.vue';
import SearchBar from '~/components/SearchBar';
import * as animationData from '~/assets/lottie/gg.json';

export default {
  components: {
    Lottie,
    SearchBar,
  },
  data() {
    return {
      sub: true,
      subMenu: null,
      open: false,
      defaultOptions: {animationData: animationData.default, renderer: 'svg'},
      animationSpeed: 1,
    };
  },
  computed: {
    ...mapState({mainMenu: state => state.mainMenu}),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.open = false;
        this.subMenu = null;
      },
    },
  },
  methods: {
    toggleSubMenu(val) {
      if (this.subMenu === val) {
        this.subMenu = null;
      } else {
        this.subMenu = val;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    stop: function () {
      this.anim.stop();
    },
    play: function () {
      this.anim.play();
    },
    pause: function () {
      this.anim.pause();
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style scoped>
.sub-menu {
  top: 100%;
  left: -0.5rem;
}

.icon {
  margin-bottom: .1rem;
}
</style>
