export const state = () => ({
  release: process.env.RELEASE,
  page: {},
  mainMenu: [],
  footerMenu: [],
  homeMenu: [],
  settings: {},
});

export const actions = {
  async nuxtServerInit({commit}) {
    const [menus, settings] = await Promise.all([
      this.$axios.get('/cached/menu/combined'),
      this.$axios.get('/cached/settings'),
    ]);

    commit('SET_MENUS', menus.data);
    commit('SET_SETTINGS', settings.data);
  },
  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENUS(state, payload) {
    state.mainMenu = payload.main;
    state.footerMenu = payload.footer;
    state.homeMenu = payload.home;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
};
