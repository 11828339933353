<template>
  <footer>
    <div class="container flex flex-col sm:flex-row items-center justify-between py-6 md:py-6 lg:py-4">
      <ul
        class="w-full sm:w-1/2 md:w-10/12 lg:w-1/2 xl:w-5/12 xxl:w-4/12 flex flex-col md:flex-row items-center justify-between"
      >
        <li v-if="$route.path === '/en'" class="font-semibold md:font-normal">Quicklink:</li>
        <li v-else class="font-semibold md:font-normal">Snel naar:</li>
        <li v-for="link in footerMenu" :key="link.title" class="py-2 md:py-0">
          <nuxt-link
            :to="link.url"
            :aria-label="link.title"
            :class="{'text-primary border-b-2 border-primary bold-link': $route.path === link.url}"
            class="hover:text-primary hover:border-b-2 hover:border-primary bold-link-hover"
            v-text="link.text"
          />
        </li>
      </ul>
      <ul
        class="w-full sm:w-1/2 flex md:hidden lg:w-5/12 xxl:w-4/12 lg:flex flex-col md:flex-row justify-center md:justify-between items-center text-sm mt-3 md:mt-0"
      >
        <li v-if="$route.path === '/en'" class="mb-3 md:mb-0">Golfgeschiedenis is an initiative of:</li>
        <li v-else class="mb-3 md:mb-0">Golfgeschiedenis is een initiatief van:</li>
        <li v-for="image in footerImages" :key="image.name">
          <a
            :href="image.url"
            :alt="image.name"
            :aria-label="`Bezoek de site van ${image.name}`"
            target="_blank"
            rel="noopener"
          >
            <img :src="image.src" :alt="image.name" aria-hidden="true" class="h-16 md:h-14 my-2 md:my-0 object-contain">
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      buttonText: 'Draag bij aan golfgeschiedenis.nl',
      footerImages: [
        {
          src: require('~/assets/img/kngf.png'),
          name: 'Koninklijke Nederlandse Golf Federatie',
          url: 'https://ngf.nl/',
        },
        {
          src: require('~/assets/img/knkb.png'),
          name: 'Koninklijke Nederlandsche Kolf Bond',
          url: 'https://kolfbond.nl/',
        },
      ],
    };
  },
  computed: {
    ...mapState({footerMenu: state => state.footerMenu}),
  },
};
</script>
